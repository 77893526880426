import React, { createContext, useState, useContext, ReactNode } from "react";

interface GenderContextType {
  gender: string;
  setGender: (gender: string) => void;
}

const GenderContext = createContext<GenderContextType | undefined>(undefined);

export const GenderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [gender, setGender] = useState('male');
  console.log(gender, 'gender');

  return (
    <GenderContext.Provider value={{ gender, setGender }}>
      {children}
    </GenderContext.Provider>
  );
};

export const useGender = () => {
  const context = useContext(GenderContext);
  if (!context) {
    throw new Error("useGender must be used within a GenderProvider");
  }
  return context;
};
