import * as React from "react";
import { GenderProvider } from "../components/GenderContext";
const Layout = ({ children }) => {
  return (
    <>
      <GenderProvider>
        <main>{children}</main>
      </GenderProvider>
    </>
  );
};

export default Layout;
